import { DEFAULT_TIME, MIN_HOUR_GAP } from '@constants/index';
import { Locale } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { DateTime } from 'luxon';

import { rentennialsTimeUtils } from './rentennialDateTZ';
import { getHalfHours, halfHours } from './slots';

const {
  getDateTime,
  getDateInBrowserTZ,
  isTodayOrTomorrow,
  transform: {
    convertToDateTime,
    convertToUTC,
    convertFromUTCToTZ,
    convertVehicleDateToBrowserDateAddingBrowserOffset,
    convertVehicleDateToBrowserDateStartOfDay
  },
  operations: { differenceBetween, intervalBetween, addToDateTime },
  offset: { getCurrentTZ, getFixedOffset, getOffset, setDefaultTZ, setNewTZ, getBrowserTZ }
} = rentennialsTimeUtils;

export {
  addToDateTime,
  convertFromUTCToTZ,
  convertToDateTime,
  convertToUTC,
  convertVehicleDateToBrowserDateAddingBrowserOffset,
  convertVehicleDateToBrowserDateStartOfDay,
  differenceBetween,
  getBrowserTZ,
  getCurrentTZ,
  getDateInBrowserTZ,
  getDateTime,
  getFixedOffset,
  getHalfHours,
  getOffset,
  halfHours,
  isTodayOrTomorrow,
  setDefaultTZ,
  setNewTZ
};

export const sanitizieDates = (date: Date, defaultDate?: Date) => {
  if (Number.isNaN(date.getTime())) {
    return defaultDate ? defaultDate : new Date();
  }
  return date;
};

export const getDaysBetweenRange = (initialDate: string | Date | DateTime, finalDate: string | Date | DateTime) => {
  (initialDate instanceof DateTime ? initialDate : convertToDateTime(initialDate)).startOf('day');
  const fromDate = (initialDate instanceof DateTime ? initialDate : convertToDateTime(initialDate)).startOf('day');
  const toDate = (finalDate instanceof DateTime ? finalDate : convertToDateTime(finalDate)).startOf('day');
  const response =
    (differenceBetween(toDate, fromDate, ['days']).days || 0) > 0 ? intervalBetween(fromDate, toDate) : [];
  return [fromDate, ...response, toDate];
};

export const getDateTimeSet = (dates: Date[]) => {
  return new Set(dates.map((currentDate) => currentDate.getTime()));
};

export const isRangeDateValid = (currentDateRange: string[] | DateTime[], disableDatesTime: Set<number>) => {
  return !getDaysBetweenRange(currentDateRange[0], currentDateRange[1]).some((date) => {
    return disableDatesTime.has(date.toUTC().startOf('day').toMillis());
  });
};

export const getMinDate = (hourGap: number = MIN_HOUR_GAP) => {
  const currentTZ = getCurrentTZ();
  setDefaultTZ();
  try {
    const today = getDateTime();
    const fixedToday = today.plus({ hours: hourGap });
    const tomorrowInitDate = today.plus({ day: 1 }).startOf('day');

    const minutesToTomorrow = differenceBetween(tomorrowInitDate, fixedToday, ['minutes']).minutes || 0;

    if (today.day !== fixedToday.day || 30 >= minutesToTomorrow) {
      return {
        date: 30 >= minutesToTomorrow ? tomorrowInitDate : fixedToday.startOf('day'),
        isToday: false
      };
    }
    return {
      date: today.startOf('day'),
      isToday: true
    };
  } finally {
    setNewTZ(currentTZ);
  }
};

const buildDefaultInitialDate = (hourGap: number = MIN_HOUR_GAP, initialHour: string = DEFAULT_TIME) => {
  const { date, isToday } = getMinDate(hourGap);
  const currentHalfHours = getHalfHours({ isToday, isTomorrow: !isToday });
  return {
    fromDate: date.toISO()!,
    fromDateTime:
      parseInt(currentHalfHours[0].replace(':', ''), 10) > parseInt(initialHour.replace(':', ''), 10)
        ? currentHalfHours[6]
          ? currentHalfHours[6]
          : currentHalfHours[0]
        : initialHour,
    toDate: date.plus({ days: 2 }).toISO()!,
    toDateTime: DEFAULT_TIME,
    persist: 'true'
  };
};

export const DEFAULT_SEARCH_VALUES = {
  ...buildDefaultInitialDate()
};

export const getNextTime = (toDate: string | Date, hours?: string) => {
  const { isToday, isTomorrow } = isTodayOrTomorrow(convertToDateTime(toDate));
  const currentHalfHours = getHalfHours({ isToday, isTomorrow });

  if ((isToday || isTomorrow) && !currentHalfHours.includes(hours || DEFAULT_TIME)) {
    return currentHalfHours[0];
  }
  return hours || DEFAULT_TIME;
};

export const convertHourToNumber = (hour: string) => {
  const splittedHour = hour.split(':');
  return parseFloat(`${parseInt(splittedHour[0], 10)}.${parseInt(splittedHour[1], 10) === 30 ? 5 : 0}`);
};

export const parseTimeSlot = (slot: string) => {
  return parseInt(slot.replace(':', ''), 10);
};

export const generateFixTimeSlot = (dateFrom: string, fromDateTime: string = DEFAULT_TIME) => {
  const { isToday, isTomorrow } = isTodayOrTomorrow(convertToDateTime(dateFrom));
  const currentHalfHours = getHalfHours({ isToday, isTomorrow });
  if (isToday) {
    return currentHalfHours.includes(fromDateTime) ? fromDateTime : currentHalfHours[0];
  }
  if (isTomorrow) {
    return (
      (!getMinDate().isToday && (currentHalfHours.includes(fromDateTime) ? fromDateTime : currentHalfHours[0])) ||
      fromDateTime
    );
  }
  return fromDateTime || DEFAULT_TIME;
};

export const getDifferenceBetweenDates = (
  finalDate: string | Date | DateTime,
  initialDate: string | Date | DateTime
) => {
  return differenceBetween(finalDate, initialDate, ['day']).days || 0;
};

export const getLocale = (locale: string): Locale => {
  const prefLocale = locale.replace('-', '');
  // @ts-ignore
  return Locales[prefLocale] ?? Locales[prefLocale.substring(0, 2)] ?? Locales.es;
};
